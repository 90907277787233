<template>
  <header class="header" :class="{ mainpage: isMain }">
    <div class="header__wrapper">
      <router-link to="/" class="header__logo-link">
        <div class="header__logo">
          <img src="/img/1.png" alt="" class="header__logo-img" />
        </div>
      </router-link>
      <div class="wrap">

         <div class="header__tgbots">
            <a href="https://t.me/ndt_info_bot">
              <img src="/img/chat.svg" />
              <p>Задать вопрос</p>
            </a>

            <a href="https://t.me/eipcstat_bot">
              <img src="/img/chat.svg" />
              <p>Статистика КЭР и ППЭЭ</p>
            </a>
          </div>

        <div class="header__bloks">
          
         

          <div class="header__block-lk">
            <a href="#" class="header__botton-lk" type="button">
              <img src="/img/33.png" alt="" class="header__botton-img" />
            </a>
            <div class="header__block-text" :class="{ opened: isVisible }">
              <a href="/NDT/index.php" class="header__link-reg"
                >Вход в личный кабинет</a>
              <a href="/NDT/Registration.php" class="header__link-reg-2"
                >регистрация</a>
            </div>
          </div>
          <a
            href="#burger"
            class="header__menu"
            type="button"
            @click="toggleMenu"
            :class="{ opened: isVisible }"
          >
            <img src="/img/4.png" alt="" class="header__menu-img" />
          </a>
        </div>
      </div>
    </div>
    <h1 class="header__m-title">БЮРО НАИЛУЧШИХ<br />ДОСТУПНЫХ ТЕХНОЛОГИЙ</h1>
    <div class="bg-pl"></div>
    <div id="burger" class="header__burger" :class="{ opened: isVisible }">
      <div class="wrapp">
        <div class="burger__bloks">
          <a href="#burger" class="burger__menu" type="button">
            <img
              src="/img/4.png"
              alt=""
              class="burger__menu-img"
              :class="{ opened: isVisible }"
            />
            <a id="close" class="link-close" href="#" @click="toggleMenu"
              ><img src="/img/26.png" alt="" class="burger__menu-img2"
            /></a>
          </a>
        </div>
      </div>
      <div class="burger__wrapper-list">
        <ul class="burger__list">
          <router-link to="/about/regulation" @click="toggleMenu">
            <li class="burger__line">
              <img class="burger__link-img" src="/img/25.png" alt="" />О нас
            </li>
          </router-link>
          <router-link to="/contacts" @click="toggleMenu">
            <li class="burger__line">
              <img class="burger__link-img" src="/img/25.png" alt="" />Контакты
            </li>
          </router-link>
          <router-link to="/documents" @click="toggleMenu">
            <li class="burger__line">
              <img class="burger__link-img" src="/img/25.png" alt="" />Документы
            </li>
          </router-link>
          <router-link
            :to="'/trg/' + new Date().getFullYear()"
            @click="toggleMenu"
          >
            <li class="burger__line">
              <img
                class="burger__link-img"
                src="/img/25.png"
                alt=""
              />Технические рабочие группы
            </li>
          </router-link>
          <router-link to="/tk113/about" @click="toggleMenu">
            <li class="burger__line">
              <img
                class="burger__link-img"
                src="/img/25.png"
                alt=""
              />Технический комитет 113
            </li>
            <!-- <div> to="/tk113/meetings" @click="toggleMenu"</div> -->
          </router-link>
          <router-link to="/tk113/meetings" @click="toggleMenu">
          <li class="burger__line">
            <img class="burger__link-img" src="/img/25.png" alt="" />Заседания
          </li>
          </router-link>
          <router-link to="/itc" @click="toggleMenu">
          <li class="burger__line">
            <img class="burger__link-img" src="/img/25.png" alt="" />Перечень ИТС НДТ
          </li>
          </router-link>          
          <router-link
            :to="'/discussion/' + new Date().getFullYear()"
            @click="toggleMenu"
          >
            <li class="burger__line">
              <img class="burger__link-img" src="/img/25.png" alt="" />Публичное
              обсуждение
            </li>
          </router-link>
          <router-link to="/publications/articles" @click="toggleMenu">
          <li class="burger__line">
            <img class="burger__link-img" src="/img/25.png" alt="" />Публикации
          </li>
          </router-link>
          <a href="http://xn--90ag0adjp3f.xn--p1ai/">
            <li class="burger__line">
              <img class="burger__link-img" src="/img/25.png" alt="" />Деловые
              игры
            </li>
          </a>
          <router-link to="/mvs/documents" @click="toggleMenu">
            <li class="burger__line">
              <img
                class="burger__link-img"
                src="/img/25.png"
                alt=""
              />Межведомственный совет
            </li>
          </router-link>
          <router-link to="/itc-orders" @click="toggleMenu">
            <li class="burger__line">
              <img
                class="burger__link-img"
                src="/img/25.png"
                alt=""
              />Приказы по технологическим показателям НДТ
            </li>
          </router-link>          
        </ul>
      </div>
    </div>
  </header>
  <AmBreadcrumbs :showCurrentCrumb="true" v-if="!isMain" />
</template>

<script>
export default {
  name: "SuperHeader",
  data() {
    return {
      thisyear: new Date().getFullYear(),
      isVisible: false,
      isMain: true,
    };
  },
  mounted() {
    if (this.$route.path == "/") this.isMain = true;
  },
  methods: {
    toggleMenu() {
      this.isVisible = !this.isVisible;
    },
  },
  watch: {
    "$route.path"() {
      if (this.$route.path == "/") {
        this.isMain = true;
      } else {
        this.isMain = false;
      }
    },
  },
};
</script>
<style lang="scss">
body {
  font-family: "PragmaticaC", sans-serif;
  font-size: 15px;
  line-height: 1.2;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  min-width: 320px;
  background: #fafafa;
}

@font-face {
  font-family: "PragmaticaC", sans-serif;
  font-weight: normal;
  font-style: normal;
  src: local("PragmaticaC"), url("/img/family/PragmaticaC") format("otf");
}

@font-face {
  font-family: "PragmaticaC", sans-serif;
  font-weight: bold;
  font-style: normal;
  src: local("PragmaticaC"), url("/img/family/PragmaticaC-Bold") format("otf");
}

@font-face {
  font-family: "PragmaticaC", sans-serif;
  font-weight: bold;
  font-style: italic;
  src: local("PragmaticaC"),
    url("/img/family/PragmaticaC-Bolditalic") format("otf");
}

@font-face {
  font-family: "PragmaticaC", sans-serif;
  font-weight: normal;
  font-style: italic;
  src: local("PragmaticaC"), url("/img/family/PragmaticaC-italic") format("otf");
}

@font-face {
  font-family: "PragmaticaC", sans-serif;
  font-weight: normal;
  font-style: normal;
  src: local("PragmaticaCondC"),
    url("/img/family/PragmaticaCondC") format("otf");
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
.main {
  position: relative;
  border: 1px solid white;
}
/*-------------------------Navigation--------------------------------------*/

.am-breadcrumbs {
  margin: 0 !important;
  /*font-size: 1.2vw;*/
  position: relative;
  z-index: 20;//my
}
.am-breadcrumbs__link {//my
  transition: .3s !important;
  display: inline-block !important;
}
.am-breadcrumbs__item:not(:last-child) .am-breadcrumbs__link:hover {//my
    color: rgb(0, 0, 0) !important;
    transform: scale(1.03) !important;
    
}
.am-breadcrumbs__list {
/*  display: block;
  text-align: left;
  margin: 1vw 8.8vw auto !important;*/
  padding: 1vw 2vw !important;
  position: absolute;
}
@media (max-width: 850px) {
  .wrap .header__bloks {
    width: auto !important;
  }

  .header .header__wrapper {
    gap: 10px;
  }
}
@media (max-width: 480px) {
  
  .am-breadcrumbs {
    font-size: 3vw;
  }

  .header__tgbots {
    min-width: auto !important;
  }

  .header__tgbots a {
    padding-bottom: 1px;
  }

  .header__tgbots a p {
    font-size: 12px !important;
  }

  .header__wrapper {
    height: auto !important;
  }

}

@media (max-width: 375px) {
  .header__tgbots {
    display: none !important;
  }
}
/*-------------------------Navigation-END----------------------------------*/
.header {
  background: url("/img/zavod.png") no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  height: 650px;
  position: relative;
  z-index: 2;

  .header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    padding: 0 10px;
    a {
      .header__logo {
        .header__logo-img {
          width: 140px;
          //height: calc(40vw / 7);
          //position: absolute;
          left: 2vw;
          transition: 0.5s;
        }
        @media (max-width: 480px) {
            height: 10vw;
            width: auto;
            margin-top: 0;
          }
        .header__logo-img:hover {
          transform: scale(1.03);
        }        
      }
    }
    .wrap {
      display: flex;
      align-items: center;
      gap: 50px;
      
      .header__bloks {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 390px;

        .header__block-lk {
          display: flex;
          justify-content: space-between;
          align-items: center;
          z-index: 9999;
          /*margin-right: 130px;*/

          .header__botton-lk {
            padding: 0 1.1vw 0 0;
          }
          .header__block-text {
            display: flex;
            flex-direction: column;
            color: #000;

            .header__span {
              font-size: calc(3px + 12 * (100vw / 1280));
              font-weight: bold;
            }
            .header__link-reg {
              color: #000;
              text-decoration: none;
              // font-style: italic;
            }
            .header__link-reg-2 {
              color: #959595;
              text-decoration: underline;
              text-underline-offset: 4px;
              font-weight: 400;
              // border-bottom: 2px solid #959595;
            }
          }
          .header__block-text.opened {
            color: rgb(185, 219, 229);
            .header__link-reg {
              color: rgb(185, 219, 229);              
              // font-style: italic;
            }
          }
        }
      }
    }

    @media(max-width:550px) {
      .header__bloks {
        width: 280px !important;
      }
    }

    @media(max-width:480px) {
      .header__bloks {
        width: 100px !important;
      }
    }

    .header__menu {
      .header__menu-img {
        width: 50px;
        /*margin-right: 1vh;*/
      }
    }
    .header__menu.opened {
      display: none;
    }
  }
  .header__m-title {
    display: none;
  }
  .bg-pl {
    background: url(/img/header_menu.png) no-repeat;
    background-size: 100%;
    position: absolute;
    top: 0;
    right: 0;
    width: 39%;
    z-index: -1;
  }
  .header__burger {
    background-size: auto;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0px;
    right: 0;
    z-index: 111;
    background-size: 100%;
    width: calc(3px + 495 * (100vw / 1280));
    background: #246379;
    width: 517px;
    height: 240%;

    .wrapp {
      
      .burger__bloks {
        /*color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        padding: 10px;
        right: 20px;
        top: 20px;*/

        .burger__menu {
          .burger__menu-img {
            height: calc(3px + 30 * (100vw / 1280));
          }
          .burger__menu-img.opened {
            display: none;
          }
        }
        .link-close {
          position: absolute;
          bottom: calc(3px + 44 * (100vw / 1280));
          top: 0;
          right: 0;

          .burger__menu-img2 {
            position: absolute;
            right: 25px;
            top: 40px;
            z-index: 999;
            width: 30px;
          }
        }
      }
    }

    .burger__wrapper-list {
      .burger__list {
        position: absolute;
        margin: 0px;
        right: 20px;
        top: 80px; 
       // top: 80px;
      //  margin-left: 11%;
      //  padding: 0;
        

        a {
          text-decoration: none;
        }

        .burger__line {
          border-bottom: 2px solid #6591a0;
          padding: 4.8px 0;
          list-style-type: none;
          display: flex;
          align-items: center;
          /*width: 21vw;*/
          font-size: 20px;
          color: #fafafa;
          line-height: 30px;
          cursor: pointer;
          position: relative;
          top: 3vw;
          
          .burger__link-img {
            position: relative;
            right: 11px;
            height: calc(3px + 12 * (100vw / 1800));
          }
        }
      }
    }
  }
  .header__burger.opened {
    visibility: visible;
    opacity: 0.9;
  }
}

.header__tgbots {
    display: flex;
    flex-direction: column;
    min-width: 210px;
    gap: 10px;
  }

  .header__tgbots a {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid rgb(0, 95, 132);
    color: rgb(0, 95, 132);
    text-decoration: none;
    padding-bottom: 3px;
  }

  .header__tgbots a p {
    margin: 0;
    font-size: 15px;
    font-weight: 450;
  }

.header.mainpage {
  background: url(/img/fon.jpg) no-repeat;
  background-size: cover;
  height: 654px;
  background-position-x: center;
  background-position-y: top;
  .header__m-title {
    display: block;
    // width: 60%;
    font-size: 18px;
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: #decfae;
    font-weight: bold;
    margin: 0;
  }
  .header__wrapper {
    .wrap {      
      .header__bloks {
        .header__block-lk {
          /*margin-top: 0.5vh;*/
          .header__block-text {
            color: #000;
            margin-bottom: 5px;

            .header__link-reg {
              color: #000;
              text-decoration: none;
              // font-style: italic;
              // font-size: calc(12vw / 13);
              font-weight: 400;
            }
            .header__span {
              font-size: calc(13vw / 13);
            }
          }
          .header__block-text.opened {
            color: rgb(255, 255, 255);
            .header__link-reg {
              color: rgb(255, 255, 255);
              text-decoration: none;
            }
          }
        }
        .header__menu {
          .header__menu-img {
            //margin-right: 1.5vw;
          }
        }
      }
    }
    .header__logo-link {
      .header__logo {
        .header__logo-img {
          background: url("/img/logo.png") no-repeat;
          background-size: contain;
          //position: absolute;
          // top: 0.4vw;
          left: 2vw;
          width: 140px;
          /*width: 8.5vw;
          height: calc(10vw / 1.97);*/
          cursor: pointer;
          @media (max-width: 480px) {
            height: 60px;
            width: auto;
            margin-top: 0;
          }
        }
      }
    }
  }
  .header__burger {
    background: url(/img/menu_over.png) no-repeat;
    background-size: contain;
    width: 744px;
    height: 100%;
    position: absolute;
    right: 0px;
    background-size: cover;
    background-position-y: bottom;

    

    .burger__wrapper-list {
      .burger__list {
        right: 20px;
        margin: 0;
        

        .burger__line {
          border-bottom: 2px solid #6591a0;
          cursor: pointer;
          padding: 4.8px 0;
          font-size: 20px;
          display: flex;
          align-items: center;
          

          .burger__link-img {
            display: none;
          }
        }
        .burger__line::before {
          content: "";
          margin-right: 5px;
          background: url(/img/str.png) no-repeat;
          width: calc(100vw / 75.05);
          height: calc(100vw / 101.05);
          background-size: calc(100vw / 101.05);
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .header,
  .header.mainpage {
    background: url(/img/bgM.png) no-repeat;
    /*background-size: 100%;
    height: 654px;
    height: calc(100vw / 1.4);
    width: 100%;
    display: block;
    z-index: 7;*/

    .header__m-title {
      /*display: inline-block;
      text-transform: uppercase;
      color: #e4d1a7;
      font-size: calc(100vw / 17);
      position: relative;
      top: 53%;
      left: 5%;
      width: 90%;
      max-width: 380px;
      margin: 0;*/
    }

    .header__wrapper .wrap {
      gap: 20px;
    }

    .header__wrapper {
      height: 48px;
      top: 2vw;
      position: relative;
      gap: 20px;

      a {
        .header__logo {
          .header__logo-img {
            /*height: 10vw;
            left: 2vw;*/            
            margin-top: 0;
          @media (max-width: 480px) {
            height: 10vw;
            width: auto;
            margin-top: 0;
          }            
          }
        }
      }  

      .wrap {
        

        .header__bloks {
          /*top: 5px;*/

          .header__block-lk {
            .header__botton-lk {
              .header__botton-img {
                width: 40px
              }
            }
            .header__block-text {
              display: none;
            }
          }
          .header__menu {
            .header__menu-img {  
              width: 35px;
            }
          }
        }
      }
    }  

    .header__burger {
      background-image: none;
      width: 100%;
      height: 100%;
      background-color: #164460;

      .wrapp {
        .burger__bloks {
          top: 11px;

          .burger__menu {
            .burger__menu-img {
              display: none;
            }
            .link-close {
              position: absolute;
              /*right: calc(3px + 48 * (100vw / 1280));
              bottom: calc(3px + 44 * (100vw / 1280));*/

              .burger__menu-img2 {
                position: absolute;
                /*right:60px;
                top: 0.5vw;*/
                top: 15px;
                width: 30px;
              }
            }
          }
        }
      }
      .burger__wrapper-list {
        .burger__list {
          position: absolute;
          top: calc(3px + 250 * (100vw / 1280));
          left: calc(3px + -20 * (100vw / 1280));
          margin-left: 9%;

          .burger__line {
            width: calc(3px + 960 * (100vw / 1280));
            font-size: calc(3px + 50 * (100vw / 1280));
            line-height: 30px;
            cursor: pointer;
            border-bottom: 2px solid #4a6e83;

            .burger__link-img {
              display: none;
            }
          }
          .burger__line::before {
            content: "";
            margin-right: 7px;
            background: url(/img/str.png) no-repeat;
            width: 30px;
            height: 20px;
          }
        }
      }
    }
    .header__burger.opened {
      visibility: visible;
      opacity: 0.9;
    }
  }
}

</style>